import { useEffect, useState } from 'react'
import Box from '../../Global/Box'
import LayoutTable from '../../Global/LayoutTable'
import { currencyFormatToAny } from '../../Helpers/HelperCurrencyFormat'
import { useQuery } from '@apollo/client'
import ContentHeader from '../../Layout/ContentHeader'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import {
  DELETe_CONCEPT_TO_BILLING,
  GET_ALL_DOCUMENTS_TO_BILLING_CONCEPTS,
} from '../../../graphql/Catalog/Billing/documenttobillingconcepts'

const InformationConceptsVerification = ({
  documentId,
  setDocumentId,
  refModal,
  refetch,
  closeModal,
  setCloseModal,
  handleCreateOrEditConcept,
  idConcept,
  setIdConcept,
  modalEditConcept,
  setModalEditConcept,
}) => {
  const [dataTable, setDataTable] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const {
    data,
    loading,
    error,
    refetch: refetchConcepts,
  } = useQuery(GET_ALL_DOCUMENTS_TO_BILLING_CONCEPTS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
      idDocument: +documentId,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    const handleRefetchConcepts = async () => {
      await refetchConcepts()
    }

    if (!modalEditConcept) handleRefetchConcepts()
  }, [modalEditConcept])

  useEffect(() => {
    try {
      if (!documentId) return
      if (loading) return
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const concepts = data.getAllDocumentsToBillingConcepts

      let listTable = concepts.rows.map((item) => {
        return {
          id: item.id,
          sku: item.sku,
          descripción: item.description,
          cantidad: item.quantity,
          'Precio unitario': currencyFormatToAny({
            number: item.unit_price,
          }),
          subtotal: currencyFormatToAny({ number: item.subtotal }),
          impuestos: currencyFormatToAny({ number: item.tax }),
          'Tasa de impuestos':
            item.documentBillingConceptsToDocumentBillingTaxes.name,
          // 'Tasa de impuestos': item.tax_rate,
          total: currencyFormatToAny({ number: item.total }),
          'Código SAT': item.sat_code,
        }
      })

      setDataTable(listTable)
      setTotalCount(concepts.count)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [data, loading, error, refetchConcepts])

  // const handleCloseModal = (e) => {
  //   // document.getElementById('file').value = null
  //   setRfcClientSelected(null)
  //   setClientSelected(null)
  //   setInputValue('restart')
  //   setCloseModal(false)
  //   setDocumentId(null)
  //   // if (documentId) setDocumentId(null)
  //   refModal.current.click()
  // }

  return (
    <>
      {/* <ContentHeader title="" windowTitle="Facturación" /> */}

      <Box
        // btnRedTxt="Regresar"
        // btnRedPath="/documenttobilling"
        // btnSecondFunction={() => handleCloseModal()}
        // btnSecondFunctionTitle="Cancelar"
        // btnSecondFunctionDismissModal={() => handleCloseModal()}
        title=""
        withCard={false}
        content={
          <>
            <LayoutTable
              withCard={false}
              hideId
              data={dataTable}
              totalCount={totalCount}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              hideDetails
              // hideActions
              actionBtnEdit={handleCreateOrEditConcept}
              headersButtons
              extraHeaderButtonFunction={() => handleCreateOrEditConcept()}
              extraHeaderButtonFunctionText="Agregar concepto"
              hideAddNew
              exportImportButton={false}
              gql={DELETe_CONCEPT_TO_BILLING}
              refetchFunctionQueries={async () => {
                await refetch()
                await refetchConcepts()
              }}
            />
          </>
        }
      />
    </>
  )
}

export default InformationConceptsVerification
