import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, set, useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  CFDI_DATA,
  OPTIONS_MONTH,
  PAYMENT_FORMS,
  PAYMENT_METHODS,
} from '../DataBilling/DataBilling'
import { GET_ALL_CLIENTS_TO_BILLING_MUTATIION } from '../../../graphql/Catalog/Billing/documenttobillingclients'
import Box from '../../Global/Box'
import InputController from '../../Global/InputController'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { toastSweetAlertNotifications } from '../../Helpers/ToastSweetAlertNotifications'
import {
  GET_ONE_DOCUMENT_TO_BILLING,
  UPDATE_DOCUMENT_TO_BILLING,
} from '../../../graphql/Catalog/Billing/documenttobilling'
const InformationBillingVerification = ({
  documentId,
  setDocumentId,
  refModal,
  refetch,
  closeModal,
  setCloseModal,
  handleCreateBillingOrRebilling,
  editDocument,
  documentInvoice,
  setDocumentInvoice,
  // setEditDocument,
}) => {
  const [optionsMonth, setOptionsMonth] = useState([])
  const [optionsYear, setOptionsYear] = useState([])
  const [clientSelected, setClientSelected] = useState(null)
  const [rfcClientSelected, setRfcClientSelected] = useState(null)
  const [clientOptions, setClientOptions] = useState([])
  const [optionsCFDI, setOptionsCFDI] = useState([])
  const [optionsPaymentMethod, setOptionsPaymentMethod] = useState([])
  const [optionsPaymentForm, setOptionsPaymentForm] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [infoDocument, setInfoDocument] = useState(null)

  const validationSchema = yup.object().shape({
    status: yup.string(),
    documentId: yup.string(),
    client_uid: yup.string().typeError('Cliente es requerido').required(),
    cfdi_use: yup.string().typeError('Uso de CFDI es requerido').required(),
    payment_method: yup
      .string()
      .typeError('Método de pago es requerido')
      .required(),
    payment_form: yup
      .string()
      .typeError('Forma de pago es requerido')
      .required(),
    month: yup.string().when([], {
      is: () => rfcClientSelected === 'XAXX010101000',
      then: yup.string().typeError('Mes es requerido').required(),
      otherwise: yup.string().nullable(),
    }),
    year: yup.string().when([], {
      is: () => rfcClientSelected === 'XAXX010101000',
      then: yup.string().typeError('Año es requerido').required(),
      otherwise: yup.string().nullable(),
    }),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const {
    loading,
    error,
    data,
    refetch: refetchOneDocument,
  } = useQuery(GET_ONE_DOCUMENT_TO_BILLING, {
    variables: {
      getDocumentToBillingByIdId: +documentId,
    },
    fetchPolicy: 'no-cache',
  })
  const [getClients] = useMutation(GET_ALL_CLIENTS_TO_BILLING_MUTATIION)
  const [updateBilling] = useMutation(UPDATE_DOCUMENT_TO_BILLING)
  // const [createBilling] = useMutation(CREATE_DOCUMENT_TO_BILLING)

  useEffect(() => {
    try {
      if (!documentId) return
      if (loading) return
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      if (editDocument)
        setDocumentInvoice(data.getDocumentToBillingById.invoiced)
      setInfoDocument(data.getDocumentToBillingById)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [data, loading, error, refetchOneDocument, documentId])

  useEffect(() => {
    if (!infoDocument) return
    if (CFDI_DATA) {
      let optionsCFDI = CFDI_DATA.map((item) => {
        return {
          value: item.value,
          label: `${item.value} - ${item.label}`,
        }
      })

      setOptionsCFDI(optionsCFDI)
      setValue('cfdi_use', documentId.cfdi_use)

      // setValue('cfdi_use', 'S01')
    }

    if (PAYMENT_METHODS) {
      let optionsPaymentMethod = PAYMENT_METHODS.map((item) => {
        return {
          value: item.value,
          label: `${item.value} - ${item.label}`,
        }
      })

      // console.log(optionsPaymentMethod, 'optionsPaymentMethod')

      setOptionsPaymentMethod(optionsPaymentMethod)
      setValue('payment_method', documentId.payment_method)
    }

    if (PAYMENT_FORMS) {
      let optionsPaymentForm = PAYMENT_FORMS.map((item) => {
        return {
          value: item.value,
          label: `${item.value} - ${item.label}`,
        }
      })

      setOptionsPaymentForm(optionsPaymentForm)
      setValue('payment_form', documentId.payment_form)
    }
  }, [CFDI_DATA, PAYMENT_METHODS, PAYMENT_FORMS, infoDocument])

  useEffect(() => {
    try {
      if (infoDocument) {
        // console.log(documentId, 'documentId')
        const currentYear = moment().format('YYYY')
        const lastYear = moment().subtract(1, 'years').format('YYYY')

        let optionsYear = [
          {
            value: currentYear,
            label: currentYear,
          },
          {
            value: lastYear,
            label: lastYear,
          },
        ]

        setOptionsYear(optionsYear)

        const loadClients = async () => {
          let client = ''
          // console.log('loadClients')
          const clients = await getClients({
            variables: {
              searchQuery: infoDocument.client_uid,
              limit: 10,
              offset: 0,
            },
            fetchPolicy: 'no-cache',
          })

          // console.log(clients, 'clients USEEFFECT LOAD CLIENTS')

          let options = []

          if (clients.data.getAllClientsToBilling.count > 0) {
            for (const row of clients.data.getAllClientsToBilling.rows) {
              options.push({
                value: row.invoice_uid,
                label: `${row.name} - ${row.rfc}`,
                rfc: row.rfc,
              })

              client = row.invoice_uid
              setClientSelected(row.invoice_uid)
              setRfcClientSelected(row.rfc)
              setValue('client_uid', row.invoice_uid)
            }

            setClientOptions(options)
          }
        }

        if (!clientSelected) loadClients()

        setClientSelected(infoDocument.client_uid)
        setRfcClientSelected(infoDocument.documentToBillingToBillingClients.rfc)
        setValue('client_uid', clientSelected)
        setValue('month', infoDocument.month)
        setValue('year', infoDocument.year)
        setValue('documentId', infoDocument.id)
        setValue('cfdi_use', infoDocument.cfdi_use)
        setValue('payment_method', infoDocument.payment_method)
        setValue('payment_form', infoDocument.payment_form)
        setValue('status', infoDocument.invoice_status)
      }
    } catch (error) {
      return toastSweetAlert(
        {
          mode: 'error',
          message: error.message,
        }
        // handleCloseModal()
      )
    }
  }, [infoDocument])

  useEffect(() => {
    // console.log(rfcClientSelected, 'rfcClientSelected')
    if (rfcClientSelected !== 'XAXX010101000') {
      setValue('month', null)
      setValue('year', null)
    }

    if (rfcClientSelected === 'XAXX010101000') {
      const currentMonth = moment().format('MM')
      const currentYear = moment().format('YYYY')

      if (editDocument) {
        if (infoDocument.month && infoDocument.year) {
          return (
            setValue('month', infoDocument.month),
            setValue('year', infoDocument.year)
          )
        }

        return setValue('month', currentMonth), setValue('year', currentYear)
      }

      if (infoDocument) {
        return (
          setValue('month', infoDocument.month),
          setValue('year', infoDocument.year)
        )
      }
      setValue('month', currentMonth)
      setValue('year', currentYear)
    }
  }, [rfcClientSelected])

  useEffect(() => {
    try {
      const loadClients = async (searchValue) => {
        let client = ''
        // console.log('loadClients')
        const clients = await getClients({
          variables: {
            searchQuery: searchValue,
            limit: 500,
            offset: 0,
          },
          fetchPolicy: 'no-cache',
        })

        // console.log(clients, 'clients USEEFFECT LOAD CLIENTS')

        let options = []

        if (clients.data.getAllClientsToBilling.count > 0) {
          for (const row of clients.data.getAllClientsToBilling.rows) {
            options.push({
              value: row.invoice_uid,
              label: `${row.name} - ${row.rfc}`,
              rfc: row.rfc,
            })

            client = row.invoice_uid
          }

          setClientOptions(options)
        }
      }

      if (inputValue !== '' && editDocument) {
        loadClients(inputValue)
      }

      if (infoDocument && inputValue === '' && editDocument) {
        const getClient = getValues('client_uid')
        // console.log(getClient, 'getClient')
        loadClients(getClient)
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [inputValue])

  const handleCloseModal = (e) => {
    // document.getElementById('file').value = null
    setRfcClientSelected(null)
    setClientSelected(null)
    setInputValue('restart')
    setCloseModal(false)
    setDocumentId(null)
    // if (documentId) setDocumentId(null)
    refModal.current.click()
  }

  const handleClientSelected = (e) => {
    // console.log(e, 'e')
    setRfcClientSelected(e.rfc)
    setClientSelected(e.value)
    setValue('client_uid', e.value)
  }

  const onSubmit = async (data) => {
    try {
      // console.log(data, 'data')

      toastSweetAlert({
        mode: 'loading',
        message: 'Actualizando documento',
      })

      const { data: dataUpdate } = await updateBilling({
        variables: {
          updateDocumentToBillingId: +data.documentId,
          input: {
            client_uid: data.client_uid,
            cfdi_use: data.cfdi_use,
            payment_method: data.payment_method,
            payment_form: data.payment_form,
            month: data.month,
            year: data.year,
          },
        },
      })

      if (dataUpdate.updateDocumentToBilling) {
        await refetch()
        await refetchOneDocument()
        return toastSweetAlert({
          mode: 'ok',
          message: 'El documento se ha actualizado correctamente',
        })
      }
    } catch (error) {
      await refetch()
      return toastSweetAlert(
        {
          mode: 'error',
          message: error.message,
        }
        // handleCloseModal()
      )
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={
            editDocument && handleCreateBillingOrRebilling
              ? handleSubmit(handleCreateBillingOrRebilling)
              : null
          }
        >
          <Box
            title="Datos del cliente"
            btnSubmit={
              editDocument && handleCreateBillingOrRebilling && !documentInvoice
            }
            // btnSubmit={!editDocument}
            btnSubmitText="Facturar"
            withCard={false}
            // btnSecondFunction={handleCloseModal}
            btnSecondFunction={
              editDocument && refModal ? (e) => handleCloseModal(e) : null
            }
            btnSecondFunctionTitle="Cancelar"
            btnSecondFunctionDismissModal={(e) => handleCloseModal(e)}
            btnThirdFunction={
              editDocument && !documentInvoice && handleCreateBillingOrRebilling
                ? handleSubmit(onSubmit)
                : null
            }
            // btnThirdFunction={
            //   editDocument && !documentInvoice ? handleSubmit(onSubmit) : null
            // }
            btnThirdFunctionClass="btn btn-info StandarModalConfirmButtonLogOut"
            btnThirdFunctionTitle="Actualizar"
            content={
              <>
                <div className="row p-2">
                  {/* <div
                    className="col-lg-12"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: '2px solid #ccc',
                      borderRadius: '5px',
                    }}
                  >
                    <input
                      id="file"
                      type="file"
                      style={{
                        margin: '10px',
                      }}
                    />
                    <label htmlFor="file">
                      <i className="fas fa-upload ml-1"> </i>{' '}
                      {` Seleccionar archivo`}
                    </label>
                  </div> */}
                  {/* <div className="invisible"> */}
                  {/* </div> */}
                  <div className="hide">
                    {' '}
                    <InputController
                      control={control}
                      name="status"
                      label=""
                      inputType="text"
                      addClass="invisible"
                      // disabled={!editDocument || documentInvoice}
                      disabled={!editDocument}
                    />
                    <InputController
                      control={control}
                      name="documentId"
                      label=""
                      inputType="text"
                      addClass="invisible"
                      disabled={!editDocument}
                      // disabled={!editDocument || documentInvoice}
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{
                      zIndex: 1001,
                    }}
                  >
                    <InputController
                      control={control}
                      name="client_uid"
                      label="Cliente"
                      placeHolderChoosen="Cliente"
                      inputType="choosen"
                      options={clientOptions}
                      menuPosition="fixed"
                      onInputChange={(val) => setInputValue(val)}
                      changeAction={(e) => handleClientSelected(e)}
                      disabled={!editDocument}
                      // disabled={!editDocument || documentInvoice}
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{
                      zIndex: 1001,
                    }}
                  >
                    <InputController
                      control={control}
                      name="cfdi_use"
                      label="Uso de CFDI"
                      inputType="choosen"
                      options={optionsCFDI}
                      placeHolderChoosen="Uso de CFDI"
                      menuPosition="fixed"
                      // disabled={!editDocument || documentInvoice}
                      disabled={!editDocument}
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{
                      zIndex: 1000,
                    }}
                  >
                    <InputController
                      control={control}
                      name="payment_method"
                      label="Método de pago"
                      inputType="choosen"
                      options={optionsPaymentMethod}
                      placeHolderChoosen="Método de pago"
                      menuPosition="fixed"
                      // disabled={!editDocument || documentInvoice}
                      disabled={!editDocument}
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{
                      zIndex: 1000,
                    }}
                  >
                    <InputController
                      control={control}
                      name="payment_form"
                      label="Forma de pago"
                      placeHolderChoosen="Forma de pago"
                      inputType="choosen"
                      options={optionsPaymentForm}
                      menuPosition="fixed"
                      // disabled={!editDocument || documentInvoice}
                      disabled={!editDocument}
                    />
                  </div>
                  {rfcClientSelected === 'XAXX010101000' && (
                    <>
                      <div
                        className="col-6"
                        style={{
                          zIndex: 999,
                        }}
                      >
                        <InputController
                          control={control}
                          name="month"
                          label="Mes"
                          inputType="choosen"
                          options={OPTIONS_MONTH}
                          placeHolderChoosen="Mes"
                          menuPosition="fixed"
                          // disabled={!editDocument || documentInvoice}
                          disabled={!editDocument}
                        />
                      </div>
                      <div
                        className="col-6"
                        style={{
                          zIndex: 999,
                        }}
                      >
                        <InputController
                          control={control}
                          name="year"
                          label="Año"
                          inputType="choosen"
                          options={optionsYear}
                          // placeholder="Año"
                          placeHolderChoosen="Año"
                          menuPosition="fixed"
                          // disabled={!editDocument || documentInvoice}
                          disabled={!editDocument}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default InformationBillingVerification
