import { gql } from '@apollo/client'

export const GET_ALL_DOCUMENTS_TO_BILLING_CONCEPTS = gql`
  query GetAllDocumentsToBillingConcepts(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $idDocument: Int
  ) {
    getAllDocumentsToBillingConcepts(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      idDocument: $idDocument
    ) {
      count
      rows {
        id
        sku
        description
        document_billing_id
        quantity
        sat_code
        subtotal
        tax
        tax_rate
        total
        unit_price
        tax_id
        documentBillingConceptsToDocumentBillingTaxes {
          id
          name
          rate
        }
      }
    }
  }
`

export const GET_ONE_DOCUMENT_TO_BILLING_CONCEPT = gql`
  query GetDocumentToBillingConceptsById($id: Int) {
    getDocumentToBillingConceptsById(id: $id) {
      id
      sku
      description
      quantity
      unit_price
      tax_rate
      subtotal
      tax
      total
      sat_code
      document_billing_id
      tax_id
      documentBillingConceptsToDocumentBillingTaxes {
        id
        name
        rate
      }
    }
  }
`

export const CREATE_CONCEPT_TO_BILLING = gql`
  mutation CreateDocumentToBillingConcepts(
    $input: inputOptionsToBillingConcepts
  ) {
    createDocumentToBillingConcepts(input: $input)
  }
`

export const UPDATE_CONCEPT_TO_BILLING = gql`
  mutation UpdateDocumentToBillingConcepts(
    $conceptId: Int
    $input: inputOptionsToBillingConcepts
  ) {
    updateDocumentToBillingConcepts(id: $conceptId, input: $input)
  }
`

export const DELETe_CONCEPT_TO_BILLING = gql`
  mutation DeleteDocumentToBillingConcepts($id: Int) {
    deleteDocumentToBillingConcepts(id: $id)
  }
`
