export const currencyFormatToAny = ({
  type = 'es-MX',
  number,
  currency = 'MXN',
  round = false,
  decimals = 2,
  style = 'currency',
}) => {
  let numberFormat = new Intl.NumberFormat(type, {
    style: style,
    currency: currency,
    maximumFractionDigits: round ? 0 : decimals,
    minimumFractionDigits: round ? 0 : decimals,
  }).format(number)

  return numberFormat
}
