import React, { useEffect, useState } from 'react'
import Box from '../../../Global/Box'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_CONCEPT_TO_BILLING,
  GET_ONE_DOCUMENT_TO_BILLING_CONCEPT,
  UPDATE_CONCEPT_TO_BILLING,
} from '../../../../graphql/Catalog/Billing/documenttobillingconcepts'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import * as Yup from 'yup'
import { FormProvider, set, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputController from '../../../Global/InputController'
import { currencyFormatToAny } from '../../../Helpers/HelperCurrencyFormat'
import { toastSweetAlertNotifications } from '../../../Helpers/ToastSweetAlertNotifications'
import { GET_ALL_DOCUMENTS_TO_BILLING_TAXES } from '../../../../graphql/Catalog/Billing/Taxes/documenttobillingtaxes'

const FormToCreateUpdateConceptBilling = ({
  conceptId,
  setConceptId,
  documentId,
  refetchConcepts,
  refetchDocument,
  refModalConcepts,
  closeModal,
  setCloseModal,
  // createConcept,
  // setCreateConcept,
  // updateConcept,
  // setUpdateConcept,
}) => {
  const [optionsTaxes, setOptionsTaxes] = useState([])
  const [taxSelected, setTaxSelected] = useState(null)
  const [taxInformation, setTaxInformation] = useState(null)

  // console.log('conceptId', conceptId)

  const validationSchema = Yup.object().shape({
    documentId: Yup.string(),
    sku: Yup.string().required('El campo SKU es requerido'),
    description: Yup.string().required('El campo Descripción es requerido'),
    quantity: Yup.number()
      .positive('El campo Cantidad debe ser positivo')
      .integer('El campo Cantidad debe ser un número entero')
      .required('El campo Cantidad es requerido')
      .typeError('El campo Cantidad es requerido'),
    unit_price: Yup.string()
      .typeError('El campo Precio unitario es requerido')
      .required('El campo Precio unitario es requerido'),
    tax_id: Yup.string()
      .required('El campo Impuesto es requerido')
      .typeError('El campo Impuesto es requerido'),
    tax_rate: Yup.number(),
    subtotal: Yup.string()
      .typeError('El campo Subtotal es requerido')
      .required('El campo Subtotal es requerido'),
    tax: Yup.string()
      .typeError('El campo de Impuestos es requerido')
      .required('El campo Impuestos es requerido'),
    total: Yup.string()
      .typeError('El campo Total es requerido')
      .required('El campo Total es requerido'),
    sat_code: Yup.string().required('El campo Código SAT es requerido'),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const {
    loading: loadingConcept,
    error: errorConcept,
    data: dataConcept,
    refetch: refetchConcept,
  } = useQuery(GET_ONE_DOCUMENT_TO_BILLING_CONCEPT, {
    variables: {
      id: +conceptId,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingTaxes,
    error: errorTaxes,
    data: dataTaxes,
  } = useQuery(GET_ALL_DOCUMENTS_TO_BILLING_TAXES, {
    fetchPolicy: 'no-cache',
  })

  const [createConcept] = useMutation(CREATE_CONCEPT_TO_BILLING)
  const [updateConcept] = useMutation(UPDATE_CONCEPT_TO_BILLING)

  useEffect(() => {
    if (closeModal) {
      reset({
        documentId: documentId,
        sku: '',
        description: '',
        quantity: '',
        unit_price: '',
        tax_rate: '',
        subtotal: '',
        tax: '',
        total: '',
        sat_code: '',
      })

      // setCloseModal(false)
      setTaxInformation(null)
      setTaxSelected(null)
    }
  }, [closeModal])

  useEffect(() => {
    try {
      if (loadingTaxes) return
      if (errorTaxes)
        return toastSweetAlert({
          mode: 'error',
          message: errorTaxes.message,
        })

      const taxes = dataTaxes.getAllDocumentToBillingTaxes.rows

      const options = taxes.map((tax) => {
        return {
          value: tax.id,
          label: `${tax.name}`,
          rate: tax.rate,
          id: tax.id,
        }
      })

      setOptionsTaxes(options)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [loadingTaxes, errorTaxes, dataTaxes])

  useEffect(() => {
    try {
      if (!conceptId) return
      if (loadingConcept) return
      if (errorConcept)
        return toastSweetAlert({
          mode: 'error',
          message: errorConcept.message,
        })

      const concept = dataConcept.getDocumentToBillingConceptsById

      // console.log(concept, 'concept')

      const dataFormatted = {
        documentId: concept.document_billing_id,
        sku: concept.sku,
        description: concept.description,
        quantity: concept.quantity,
        unit_price:
          currencyFormatToAny({
            number: concept.unit_price,
            style: 'decimal',
            decimalsMin: 0,
          }) || '0',
        tax_rate: concept.tax_rate || '0',
        tax_id: concept.tax_id,
        subtotal:
          currencyFormatToAny({
            number: concept.subtotal,
            style: 'decimal',
          }) || '0',
        tax:
          currencyFormatToAny({
            number: concept.tax,
            style: 'decimal',
          }) || '0',
        total:
          currencyFormatToAny({
            number: concept.total,
            style: 'decimal',
          }) || '0',
        sat_code: concept.sat_code,
      }

      reset(dataFormatted)
      setTaxSelected(concept.tax_id)
      // setTaxInformation(concept.documentBillingConceptsToDocumentBillingTaxes)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [loadingConcept, errorConcept, dataConcept])

  useEffect(() => {
    try {
      if (!taxInformation) return

      setValue('tax_rate', taxInformation.rate || '0')

      handleToCalculateSubtotal()
      handleToCalculateTaxes()
      handleToCalculateTotal()
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [taxInformation])

  const handleCloseModal = (e) => {
    // setCloseModal(false)
    setConceptId(null)
    setTaxSelected(null)
    setTaxInformation(null)
    reset({
      documentId: documentId,
      sku: '',
      description: '',
      quantity: '',
      unit_price: '',
      tax_rate: '',
      subtotal: '',
      tax: '',
      total: '',
      sat_code: '',
    })

    refModalConcepts.current.click()
  }

  const handleToValidateInputNumber = (value, field) => {
    if (value === '') return setValue(field, '')

    value = value.includes(',') ? value.replace(/[,]/g, '') : value

    const convertedValue = parseFloat(value)

    if (isNaN(convertedValue)) {
      return toastSweetAlertNotifications(
        {
          mode: 'info',
          message: 'El campo debe ser un número',
        },
        setValue(field, '')
      )
    }

    if (convertedValue < 0) {
      return toastSweetAlertNotifications(
        {
          mode: 'info',
          message: 'El campo debe ser un número positivo',
        },
        setValue(field, '')
      )
    }

    // if (
    //   field === 'unit_price' ||
    //   field === 'quantity' ||
    //   field === 'tax_rate' ||
    //   field === 'subtotal' ||
    //   field === 'tax' ||
    //   field === 'total'
    // ) {
    // }

    if (field === 'tax_rate') {
      // if (convertedValue === 0) setValue(field, '0')

      if (convertedValue !== 0.16 && convertedValue !== 0) {
        return toastSweetAlertNotifications(
          {
            mode: 'info',
            message: 'El campo Impuesto debe ser 0 o 0.16',
          },
          setValue(field, '')
        )
      }

      handleToCalculateSubtotal()
      handleToCalculateTaxes()
      handleToCalculateTotal()

      return setValue(field, convertedValue || '0')
    }

    if (field === 'quantity') {
      handleToCalculateSubtotal()
      handleToCalculateTaxes()
      handleToCalculateTotal()
      return setValue(field, convertedValue)
    }

    handleToCalculateSubtotal()
    handleToCalculateTaxes()
    handleToCalculateTotal()

    return setValue(
      field,
      currencyFormatToAny({
        number: convertedValue,
        style: 'decimal',
        decimalsMin: 0,
      })
    )
  }

  const handleToCalculateSubtotal = () => {
    // console.log('handleToCalculateSubtotal')
    let quantity = getValues('quantity') || 0
    let unit_price = getValues('unit_price') || 0

    unit_price = String(unit_price).includes(',')
      ? unit_price.replace(/[,]/g, '')
      : unit_price
    // if(!quantity || !unit_price) return

    const subtotal = quantity * parseFloat(unit_price)

    // console.log(subtotal, 'subtotal')

    setValue(
      'subtotal',
      currencyFormatToAny({ number: subtotal, style: 'decimal' }) || '0'
    )
  }

  const handleToCalculateTaxes = () => {
    // console.log('handleToCalculateTaxes')
    let subtotal = getValues('subtotal') || 0
    let tax_rate = getValues('tax_rate') || 0

    subtotal = subtotal.includes(',') ? subtotal.replace(/[,]/g, '') : subtotal

    // console.log(tax_rate, 'tax_rate')

    const tax = parseFloat(subtotal) * parseFloat(tax_rate)
    // console.log(tax, 'tax')

    setValue(
      'tax',
      currencyFormatToAny({ number: tax, style: 'decimal' }) || '0'
    )
  }

  const handleToCalculateTotal = () => {
    let subtotal = getValues('subtotal') || 0
    let tax = getValues('tax') || 0
    // console.log(subtotal, 'subtotal')
    // console.log(tax, 'tax')
    subtotal = subtotal.includes(',') ? subtotal.replace(/[,]/g, '') : subtotal
    tax = tax.includes(',') ? tax.replace(/[,]/g, '') : tax
    const total = parseFloat(subtotal) + parseFloat(tax)

    // console.log(total, 'total')

    setValue(
      'total',
      currencyFormatToAny({ number: total, style: 'decimal' }) || '0'
    )
  }

  const handleSelectTax = (e) => {
    // console.log(e, 'e')
    if (!e) return

    setTaxSelected(e.value)
    setTaxInformation(e)
  }

  const onSubmit = async (data) => {
    try {
      // console.log(data, 'data')

      toastSweetAlert({
        mode: 'loading',
        message: 'Procesando...',
      })

      const unitPriceFormatted = data.unit_price.includes(',')
        ? data.unit_price.replace(/[,]/g, '')
        : data.unit_price

      const subtotalFormatted = data.subtotal.includes(',')
        ? data.subtotal.replace(/[,]/g, '')
        : data.subtotal

      const taxFormatted = data.tax.includes(',')
        ? data.tax.replace(/[,]/g, '')
        : data.tax

      const totalFormatted = data.total.includes(',')
        ? data.total.replace(/[,]/g, '')
        : data.total

      if (conceptId) {
        const { data: dataUpdate } = await updateConcept({
          variables: {
            conceptId: +conceptId,
            input: {
              document_billing_id: +documentId,
              sku: data.sku,
              description: data.description,
              quantity: +data.quantity,
              unit_price: parseFloat(unitPriceFormatted),
              tax_rate: +data.tax_rate,
              subtotal: parseFloat(subtotalFormatted),
              tax: parseFloat(taxFormatted),
              tax_id: +taxSelected,
              total: parseFloat(totalFormatted),
              sat_code: data.sat_code,
            },
          },
        })

        if (dataUpdate.updateDocumentToBillingConcepts) {
          await refetchConcepts()
          return toastSweetAlertNotifications(
            {
              mode: 'ok',
              message: 'Concepto actualizado correctamente',
            },
            handleCloseModal()
          )
        }
      }

      const { data: dataCreate } = await createConcept({
        variables: {
          input: {
            document_billing_id: +documentId,
            sku: data.sku,
            description: data.description,
            quantity: +data.quantity,
            unit_price: parseFloat(unitPriceFormatted),
            tax_rate: +data.tax_rate,
            subtotal: parseFloat(subtotalFormatted),
            tax: parseFloat(taxFormatted),
            tax_id: +taxSelected,
            total: parseFloat(totalFormatted),
            sat_code: data.sat_code,
          },
        },
      })

      if (dataCreate.createDocumentToBillingConcepts) {
        await refetchConcepts()
        return toastSweetAlertNotifications(
          {
            mode: 'ok',
            message: 'Concepto creado correctamente',
          },
          handleCloseModal()
        )
      }
    } catch (error) {
      // console.log(error, 'error')
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  // console.log(errors, 'errors')
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            btnSubmit
            btnSubmitText={conceptId ? 'Actualizar' : 'Crear'}
            withCard={false}
            btnSecondFunction={(e) => handleCloseModal(e)}
            content={
              <>
                <div className="row">
                  <div className="hide">
                    <InputController
                      control={control}
                      name="documentId"
                      label="documentId"
                      addClass="invisible"
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController control={control} name="sku" label="SKU" />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="description"
                      label="Descripción"
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="sat_code"
                      label="Código SAT"
                      // disabled
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="tax_id"
                      label="Impuesto"
                      inputType="choosen"
                      options={optionsTaxes}
                      valueManual={taxSelected ? taxSelected : ''}
                      menuPosition="fixed"
                      changeAction={(e) => handleSelectTax(e)}
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="quantity"
                      label="Cantidad"
                      changeAction={(e) =>
                        handleToValidateInputNumber(e.target.value, 'quantity')
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="unit_price"
                      label="Precio unitario"
                      changeAction={(e) =>
                        handleToValidateInputNumber(
                          e.target.value,
                          'unit_price'
                        )
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="tax_rate"
                      label="Impuesto"
                      changeAction={(e) =>
                        handleToValidateInputNumber(e.target.value, 'tax_rate')
                      }
                      disabled
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="subtotal"
                      label="Subtotal"
                      disabled
                      // changeAction={(e) => handleToValidateInputNumber(e.target.value, 'subtotal')}
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="tax"
                      label="Impuestos"
                      disabled
                      // changeAction={(e) => handleToValidateInputNumber(e.target.value, 'tax')}
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputController
                      control={control}
                      name="total"
                      label="Total"
                      disabled
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default FormToCreateUpdateConceptBilling
