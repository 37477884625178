import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import {
  CREATE_DOCUMENT_TO_BILLING,
  DELETE_CONCEPT_TO_DOCUMENT,
  DOWLOAD_DOCUMENT_PDF,
  DOWNLOAD_DOCUMENT_XML,
  GET_ALL_DOCUMENTS_TO_BILLING,
  RETRY_BILLING_DOCUMENT,
} from '../../graphql/Catalog/Billing/documenttobilling'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import ContentHeader from '../Layout/ContentHeader'
import Box from '../Global/Box'
import LayoutTable from '../Global/LayoutTable'
import Swal from 'sweetalert2'
import moment from 'moment'
import ModalContent from '../Global/ModalContent'
import FormToUploadDocumentBilling from './FormToUploadDocument/FormToUploadDocumentBilling'
import InformationBillingVerification from './Views/InformationBillingVerification'
import InformationConceptsVerification from './Views/InformationConceptsVerification'
import { currencyFormatToAny } from '../Helpers/HelperCurrencyFormat'
import Tabs from '../Helpers/Tabs'
import { toastSweetAlertNotifications } from '../Helpers/ToastSweetAlertNotifications'
import {
  GET_ALL_CLIENTS_TO_BILLING_MUTATIION,
  GET_ALL_CLIENTS_TO_BILLING_QUERY,
} from '../../graphql/Catalog/Billing/documenttobillingclients'
import { STATUS_OPTIONS_DOCUMENT } from './DataBilling/DataBilling'
import FormToCreateUpdateConceptBilling from './Views/Concepts/FormToCreateUpdateConceptBilling'
const DocumentToBilling = () => {
  // STATUS
  // 1. PENDIENTE
  // 2. FACTURADO
  // 3. TIMBRADO
  // 4. CANCELADO

  // ERROR
  // 99. ERROR - EN GENERAL SEA POR ERROR DE SERVICIO, ERROR DE FACTURACIÓN, ERROR DE TIMBRADO, ERROR DE CANCELACIÓN

  const refModal = useRef(null)
  const modalRefConcept = useRef(null)

  const [dataTable, setDataTable] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [urlFile, setUrlFile] = useState(null)
  const [closeModal, setCloseModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [documentId, setDocumentId] = useState(null)
  const [verificationModal, setVerificationModal] = useState(false)
  const [optionsClients, setOptionsClients] = useState([])
  // const [optionsClientsSearch, setOptionsClientsSearch] = useState([])
  const [inputValueClient, setInputValueClient] = useState(null)

  //EditConcepts
  const [modalContent, setModalContent] = useState(null)
  const [conceptIdEdit, setConceptIdEdit] = useState(null)
  const [modalConcepts, setModalConcepts] = useState(false)
  const [documentInvoice, setDocumentInvoice] = useState(false)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
    dateTo: null,
    dateFrom: null,
    uidClient: null,
    status: null,
  })

  const { data, loading, error, refetch } = useQuery(
    GET_ALL_DOCUMENTS_TO_BILLING,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
        dateTo: pagePagination.dateTo,
        dateFrom: pagePagination.dateFrom,
        uidClient: pagePagination.uidClient,
        status: pagePagination.status,
      },
      fetchPolicy: 'no-cache',
    }
  )

  // const {
  //   loading: loadingClients,
  //   error: errorClients,
  //   data: dataClients,
  //   refetch: refetchClients,
  // } = useQuery(GET_ALL_CLIENTS_TO_BILLING_QUERY, {
  //   variables: {
  //     searchQuery: inputValueClient,
  //     limit: 100,
  //     offset: 0,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const [clientsQuery, { loading: loadingClients }] = useMutation(
    GET_ALL_CLIENTS_TO_BILLING_MUTATIION
  )

  // const [uploadCSV] = useMutation(CREATE_DOCUMENT_TO_BILLING)
  const [retryBilling] = useMutation(RETRY_BILLING_DOCUMENT)
  const [downloadFile] = useMutation(DOWNLOAD_DOCUMENT_XML)
  const [downloadFilePDF] = useMutation(DOWLOAD_DOCUMENT_PDF)

  useEffect(() => {
    try {
      if (loading) return
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const documents = data.getAllDocumentsToBilling

      let listTable = documents.rows.map((item) => {
        let concatInvoice = `${item.serie}-${item.invoice}`
        return {
          id: item.id,
          Folio: item.invoiced ? concatInvoice : 'N/A',
          Cliente: item.documentToBillingToBillingClients.name || 'N/A',
          'Fecha timbrado': item.invoice_stamped_date
            ? moment(item.invoice_stamped_date).format('DD/MM/YYYY HH:mm:ss')
            : 'N/A',
          subtotal:
            currencyFormatToAny({
              number: item.subtotal_invoice,
            }) || 'N/A',
          impuestos:
            currencyFormatToAny({
              number: item.tax_total,
            }) || 'N/A',
          total:
            currencyFormatToAny({
              number: item.total_invoice,
            }) || 'N/A',
          alert:
            item.invoice_status === 2 && item.invoiced
              ? 3
              : item.invoice_status === 1 && !item.invoiced
              ? 1
              : 2,
        }
      })

      setDataTable(listTable)
      setTotalCount(documents.count)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [data, loading, error, refetch])

  useEffect(() => {
    // console.log(documentId, 'documentId')
    if (documentId && verificationModal) {
      setTimeout(() => {
        toastSweetAlert({
          mode: 'loading',
          message:
            'Archivo cargado correctamente. Cargando datos del documento, por favor espere...',
        })
      })

      setTimeout(() => {
        document.getElementById('spanModalUploadFile').click()
        setVerificationModal(false)
        toastSweetAlertNotifications({
          mode: 'ok',
          message: 'Datos cargados correctamente',
        })
      }, 4000)
    }
  }, [documentId, verificationModal])

  useEffect(() => {
    try {
      // console.log(dataClients, 'dataClients')

      const handleQueryClients = async (value) => {
        const { data } = await clientsQuery({
          variables: {
            searchQuery: value,
            limit: 300,
            offset: 0,
          },
          fetchPolicy: 'no-cache',
        })

        if (data.getAllClientsToBilling) {
          const clients = data.getAllClientsToBilling.rows.map((client) => {
            return {
              value: client.invoice_uid,
              name: client.name,
              rfc: client.rfc,
              label: `${client.name}`,
            }
          })

          setOptionsClients(clients)
        }
      }

      if (inputValueClient) {
        handleQueryClients(inputValueClient)
      }

      if (!inputValueClient) {
        handleQueryClients()
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [inputValueClient])

  useEffect(() => {
    if (modalConcepts) {
      modalRefConcept.current.click()
    }
  }, [modalConcepts])

  const HandleImportFile = async () => {
    try {
      document.getElementById('spanModalUploadFile').click()
      setOpenModal(true)
    } catch (error) {
      await refetch()
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const filterByClient = (e) => {
    // console.log(e, 'e')
    if (e) {
      return setPagePagination({
        ...pagePagination,
        uidClient: e.value,
      })
    }

    setPagePagination({
      ...pagePagination,
      uidClient: null,
    })
  }

  const filterByStatus = (e) => {
    if (e) {
      return setPagePagination({
        ...pagePagination,
        status: e.value,
      })
    }

    setPagePagination({
      ...pagePagination,
      status: null,
    })
  }

  const handleInputChange = (e) => {
    setInputValueClient(e)
  }

  const handleFilterOptions = (option, inputValue) => {
    if (inputValue === '') {
      return option
    }

    // console.log(option, inputValue, 'option, inputValue')

    return (
      option.data.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data.rfc.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const handleFilterDateFrom = (e) => {
    // console.log(e, 'e')
    if (e) {
      return setPagePagination({
        ...pagePagination,
        dateFrom: e,
      })
    }

    setPagePagination({
      ...pagePagination,
      dateFrom: null,
    })
  }

  const handleFilterDateTo = (e) => {
    // console.log(e, 'e')
    if (e) {
      return setPagePagination({
        ...pagePagination,
        dateTo: e,
      })
    }

    setPagePagination({
      ...pagePagination,
      dateTo: null,
    })
  }

  const handleDownloadFile = async (id, type) => {
    try {
      toastSweetAlert({
        mode: 'loading',
        message: 'Descargando archivo, por favor espere...',
      })

      switch (type) {
        case 'pdf':
          const { data: dataPDF } = await downloadFilePDF({
            variables: {
              downloadFileInvoicePdfId: +id,
            },
            fetchPolicy: 'no-cache',
          })

          // console.log(data, 'data.downloadFileInvoicePDF')

          if (dataPDF.downloadFileInvoicePDF) {
            const { file, fileName } = dataPDF.downloadFileInvoicePDF

            // // setUrlFile(data.downloadFileInvoicePdf)
            // console.log(
            //   typeof data.downloadFileInvoicePDF,
            //   'data.downloadFileInvoicePDF'
            // )
            const byteCharacters = atob(file)
            // console.log(byteCharacters, 'byteCharacters')
            const byteNumbers = new Array(byteCharacters.length)
            // console.log(byteNumbers, 'byteNumbers')
            for (let i = 0; i < byteCharacters.length; i++) {
              // console.log(byteCharacters[i], 'byteCharacters.charCodeAt(i)')
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)

            const blobl = new Blob([byteArray], {
              type: 'application/pdf',
            })

            const url = URL.createObjectURL(blobl, {
              type: 'application/pdf',
              filename: fileName,
            })
            window.open(url, '_blank')

            return toastSweetAlertNotifications(
              {
                mode: 'ok',
                message: 'Archivo PDF descargado correctamente',
              }
              // document.getElementById('fileDownload').click()
            )
          }

          break
        case 'xml':
          const { data: dataXML } = await downloadFile({
            variables: {
              documentId: +id,
            },
            fetchPolicy: 'no-cache',
          })

          const link = document.createElement('a')

          if (dataXML.getFileInvoice) {
            // setUrlFile(data.getFileInvoice)
            link.href = dataXML.getFileInvoice
            link.download = 'file.xml'
            link.click()
            toastSweetAlertNotifications({
              mode: 'ok',
              message: 'Archivo XML descargado correctamente',
            })
            // document.getElementById('fileDownload').click()
            return setUrlFile(null)
          }

          break

        default:
          toastSweetAlert({
            mode: 'error',
            message: 'Error al descargar archivo',
          })
          break
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleVerificationModal = async (id) => {
    try {
      // console.log(id, 'id')
      setDocumentId(id)
      document.getElementById('spanModalUploadFile').click()
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message ? error.message : 'Error al reintentar facturar',
      })
    }
  }

  const handleCreateOrEditConcept = (id) => {
    if (!id) {
      // setConceptIdEdit(null)
      setModalConcepts(true)
      return
    }

    setConceptIdEdit(id)
    setModalConcepts(true)
    // document.getElementById('spanCreateEditConcept').click()
  }

  const handleCloseModal = (e) => {
    setDocumentId(null)
    // setVerificationModal(false)
    refModal.current.click()
  }

  const handleCreateBillingOrRebilling = async (dataForm) => {
    try {
      // console.log(dataForm, 'data')

      const response = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message: '¿Está seguro de facturar este documento?',
      })

      if (!response.isConfirmed) return

      toastSweetAlert({
        mode: 'loading',
        message:
          +dataForm.status === 1
            ? 'Facturando documento, por favor espere...'
            : 'Reintentando facturar documento, por favor espere...',
      })
      const { data } = await retryBilling({
        variables: {
          documentId: +dataForm.documentId,
        },
        fetchPolicy: 'no-cache',
      })
      if (data.retryBillingDocument) {
        await refetch()
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'El documento se ha facturado correctamente',
          },
          handleCloseModal()
        )
      }
    } catch (error) {
      await refetch()
      return toastSweetAlert(
        {
          mode: 'error',
          message: error.message ? error.message : 'Error al intentar facturar',
        },
        handleCloseModal()
      )
    }
  }

  return (
    <>
      <ContentHeader title="" windowTitle="Facturación" />

      <Box
        title="Documentos a facturar"
        content={
          <>
            <LayoutTable
              hideAddNew
              alertColor=""
              withCard={false}
              hideId
              data={dataTable}
              gql={DELETE_CONCEPT_TO_DOCUMENT}
              refetchFunctionQueries={refetch}
              totalCount={totalCount}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              componentAction={(row) => {
                return (
                  <>
                    {row.alert === 1 && (
                      <span
                        className="btn btn-sm btn-accept"
                        onClick={() => handleVerificationModal(row.id)}
                      >
                        <i class="fas fa-file-invoice fa-lg"></i>
                      </span>
                    )}

                    {row.alert === 2 && (
                      <span
                        className="btn btn-sm btn-accept"
                        onClick={() => handleVerificationModal(row.id)}
                      >
                        <i class="fas fa-sync fa-lg"></i>
                      </span>
                    )}

                    {row.alert === 3 && (
                      <div className="btn-group">
                        <button
                          className="btn btn-sm btn-accept dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-download fa-lg"></i>
                        </button>
                        <div className="dropdown-menu">
                          <p
                            className="dropdown-item"
                            data-download="pdf"
                            onClick={() => handleDownloadFile(row.id, 'pdf')}
                          >
                            <i class="far fa-file-pdf fa-lg"></i> Descargar PDF
                          </p>

                          <p
                            className="dropdown-item"
                            data-download="xml"
                            onClick={() => handleDownloadFile(row.id, 'xml')}
                          >
                            <i class="fas fa-file-code fa-lg"></i> Descargar XML
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )
              }}
              // actionBtnEdit={handleDownloadFile}
              // editText={<i class="fas fa-download fa-lg"></i>}
              extraButtonsImportExport={
                <>
                  <span
                    className="dropdown-item item-click"
                    onClick={(row) => HandleImportFile(row)}
                  >
                    <i class="fas fa-file-upload"></i> Subir archivo para
                    facturación
                  </span>
                </>
              }
              templateDataImport={[
                {
                  sku: '',
                  descripcion: '',
                  cantidad: '',
                  precio_unitario: '',
                  valor_impuesto: '',
                  subtotal: '',
                  impuestos: '',
                  total: '',
                  codigo_sat: '',
                },
              ]}
              desactiveBOM={false}
              filterChoosen={[
                {
                  id: 'client_uid',
                  name: 'Cliente',
                  className: 'position-searchInput',
                  placeholder: 'Filtra por cliente',
                  noOptionsMessage: () =>
                    loadingClients ? 'Cargando...' : 'No hay resultados',
                  options: optionsClients,
                  onChange: (e) => filterByClient(e),
                  isClearable: true,
                  onInputChange: (e) => handleInputChange(e),
                  isLoading: loadingClients,
                  // getOptionLabel: (option) => option.name,
                  filterOption: (option, inputValue) =>
                    handleFilterOptions(option, inputValue),
                },
                {
                  id: 'status',
                  name: 'Estatus',
                  className: 'position-searchInput',
                  placeholder: 'Filtra por estatus',
                  noOptionsMessage: () => 'No hay resultados',
                  options: STATUS_OPTIONS_DOCUMENT,
                  onChange: (e) => filterByStatus(e),
                  isClearable: true,
                },
              ]}
              filterRangeDateFrom
              filterRangeDateTo
              idFilterRangeDateFrom="dateFrom"
              idFilterRangeDateTo="dateTo"
              filterOnChangeRangeDateFrom={(e) =>
                handleFilterDateFrom(e.target.value)
              }
              filterOnChangeRangeDateTo={(e) =>
                handleFilterDateTo(e.target.value)
              }
            />
          </>
        }
      />
      <a id="fileDownload" href={urlFile} _target="blank"></a>

      <ModalContent
        idModal="modalUploadFile"
        title="Subir archivo para facturación"
        size="extra"
        onClose={() => {
          setOpenModal(false)
          setVerificationModal(false)
          setDocumentId(null)
        }}
        content={
          <>
            {documentId ? (
              <Tabs
                tabsContent={[
                  {
                    id: 1,
                    name: 'Conceptos',
                    content: (
                      <InformationConceptsVerification
                        documentId={documentId}
                        setDocumentId={setDocumentId}
                        refModal={refModal}
                        refetch={refetch}
                        closeModal={closeModal}
                        setCloseModal={setCloseModal}
                        handleCreateOrEditConcept={handleCreateOrEditConcept}
                        modalEditConcept={modalConcepts}
                      />
                    ),
                  },
                  {
                    id: 2,
                    name: 'Información del documento',
                    content: (
                      <InformationBillingVerification
                        documentId={documentId}
                        setDocumentId={setDocumentId}
                        refModal={refModal}
                        refetch={refetch}
                        closeModal={closeModal}
                        setCloseModal={setCloseModal}
                        handleCreateBillingOrRebilling={
                          handleCreateBillingOrRebilling
                        }
                        editDocument={true}
                        documentInvoice={documentInvoice}
                        setDocumentInvoice={setDocumentInvoice}
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <FormToUploadDocumentBilling
                setDocumentId={setDocumentId}
                refModal={refModal}
                refetch={refetch}
                closeModal={closeModal}
                setCloseModal={setCloseModal}
                openModal={openModal}
                setOpenModal={setOpenModal}
                verficationModal={verificationModal}
                setVerificationModal={setVerificationModal}
              />
            )}
          </>
        }
      />

      {modalConcepts && (
        <ModalContent
          idModal="modalCreateEditConcept"
          title="Conceptos"
          size="extra"
          // secondModal
          onClose={() => {
            setConceptIdEdit(null)
            setModalConcepts(false)
            modalRefConcept.current.click()
          }}
          content={
            <>
              <FormToCreateUpdateConceptBilling
                conceptId={conceptIdEdit}
                documentId={documentId}
                refModalConcepts={modalRefConcept}
                setConceptId={setConceptIdEdit}
                closeModal={closeModal}
                setCloseModal={setCloseModal}
                refetchConcepts={refetch}
              />
            </>
          }
        />
      )}

      <span
        id="spanModalUploadFile"
        ref={refModal}
        className="btn btn-sm invisible"
        data-toggle="modal"
        data-target="#modalUploadFile"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      ></span>

      <span
        id="spanCreateEditConcept"
        ref={modalRefConcept}
        className="invisible"
        data-toggle="modal"
        data-target="#modalCreateEditConcept"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      ></span>
    </>
  )
}

export default DocumentToBilling
