import { gql } from '@apollo/client'

export const GET_ALL_CLIENTS_TO_BILLING_QUERY = gql`
  query GetAllDocumentsToBillingClients(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllDocumentsToBillingClients(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        invoice_uid
        name
        rfc
      }
    }
  }
`

export const GET_ONE_CLIENT_TO_BILLING_QUERY = gql`
  query GetDocumentToBillingClientById($getDocumentToBillingClientByIdId: Int) {
    getDocumentToBillingClientById(id: $getDocumentToBillingClientByIdId) {
      id
      invoice_uid
      name
      rfc
      tax_system
      zip_code
      email
    }
  }
`

export const SYNC_CLIENT_TO_BILLING_MUTATION = gql`
  mutation Mutation {
    syncDocumentToBillingClients
  }
`

export const GET_ALL_CLIENTS_TO_BILLING_MUTATIION = gql`
  mutation Mutation($searchQuery: String, $limit: Int, $offset: Int) {
    getAllClientsToBilling(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        invoice_uid
        name
        rfc
      }
    }
  }
`

export const CREATE_CLIENT_BILLING = gql`
  mutation CreateDocumentToBillingClient(
    $documentToBillingClientsInput: documentToBillingClientsInput
  ) {
    createDocumentToBillingClient(
      documentToBillingClientsInput: $documentToBillingClientsInput
    )
  }
`

export const UPDATE_CLIENT_BILLING = gql`
  mutation UpdateDocumentToBillingClient(
    $updateDocumentToBillingClientId: Int
    $documentToBillingClientsInput: documentToBillingClientsInput
  ) {
    updateDocumentToBillingClient(
      id: $updateDocumentToBillingClientId
      documentToBillingClientsInput: $documentToBillingClientsInput
    )
  }
`

export const DELETE_CLIENT_TO_BILLING_MUTATION = gql`
  mutation DeleteDocumentToBillingClient($id: Int, $deleteExternal: Boolean) {
    deleteDocumentToBillingClient(id: $id, deleteExternal: $deleteExternal)
  }
`
