import { gql } from '@apollo/client'

export const GET_ALL_DOCUMENTS_TO_BILLING = gql`
  query GetAllDocumentsToBilling(
    $searchQuery: String
    $limit: Int
    $offset: Int
    $dateTo: String
    $dateFrom: String
    $uidClient: String
    $status: Int
  ) {
    getAllDocumentsToBilling(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
      dateTo: $dateTo
      dateFrom: $dateFrom
      uid_client: $uidClient
      status: $status
    ) {
      count
      rows {
        id
        file_xml_id
        file_pdf_id
        invoice
        invoice_stamped_date
        invoice_status
        invoice_uid
        invoiced
        is_active
        serie
        subtotal_invoice
        tax_total
        total_invoice
        uuid
        documentToBillingToBillingClients {
          name
          rfc
        }
      }
    }
  }
`

export const GET_ONE_DOCUMENT_TO_BILLING = gql`
  query GetDocumentToBillingById($getDocumentToBillingByIdId: Int) {
    getDocumentToBillingById(id: $getDocumentToBillingByIdId) {
      file_pdf_id
      file_xml_id
      id
      invoice
      invoice_stamped_date
      invoice_status
      invoice_uid
      invoiced
      is_active
      serie
      subtotal_invoice
      tax_total
      total_invoice
      uuid
      invoice_status
      payment_method
      payment_form
      cfdi_use
      client_uid
      month
      year
      documentToBillingToBillingClients {
        id
        invoice_uid
        name
        rfc
      }
    }
  }
`
export const CREATE_DOCUMENT_TO_BILLING = gql`
  mutation CreateDocumentToBilling(
    $file: Upload!
    $input: inputOptionsToBilling
  ) {
    createDocumentToBilling(file: $file, input: $input)
  }
`

export const UPDATE_DOCUMENT_TO_BILLING = gql`
  mutation UpdateDocumentToBilling(
    $updateDocumentToBillingId: Int
    $input: inputOptionsToBilling
  ) {
    updateDocumentToBilling(id: $updateDocumentToBillingId, input: $input)
  }
`

export const RETRY_BILLING_DOCUMENT = gql`
  mutation RetryBillingDocument($documentId: Int) {
    retryBillingDocument(id: $documentId)
  }
`
export const DOWNLOAD_DOCUMENT_XML = gql`
  mutation GetFileInvoice($documentId: Int) {
    getFileInvoice(id: $documentId)
  }
`

export const DOWLOAD_DOCUMENT_PDF = gql`
  mutation DownloadFileInvoicePDF($downloadFileInvoicePdfId: Int) {
    downloadFileInvoicePDF(id: $downloadFileInvoicePdfId) {
      file
      fileName
    }
  }
`

// export const ADD_CONCEPT_TO_DOCUMENT = gql`
//   mutation AddOrderToDocumentToBilling(
//     $documentId: Int
//     $orderId: Int
//     $inputOrderToDocumentToBilling: InputOrderToDocumentToBilling
//   ) {
//     AddOrderToDocumentToBilling(
//       documentId: $documentId
//       orderId: $orderId
//       inputOrderToDocumentToBilling: $inputOrderToDocumentToBilling
//     ) {
//       status
//       id
//     }
//   }
// `

export const DELETE_CONCEPT_TO_DOCUMENT = gql`
  mutation DeleteDocumentToBilling($id: Int) {
    deleteDocumentToBilling(id: $id)
  }
`
