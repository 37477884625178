import { gql } from '@apollo/client'

export const GET_ALL_DOCUMENTS_TO_BILLING_TAXES = gql`
  query GetAllDocumentToBillingTaxes(
    $searchQuery: String
    $limit: Int
    $offset: Int
  ) {
    getAllDocumentToBillingTaxes(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        name
        rate
        type_tax_invoice_code
      }
    }
  }
`

export const GET_DOCUMENT_TO_BILLING_TAXES = gql`
  query GetDocumentToBillingTaxesById($getDocumentToBillingTaxesByIdId: Int) {
    getDocumentToBillingTaxesById(id: $getDocumentToBillingTaxesByIdId) {
      id
      name
      rate
      type_tax_invoice_code
    }
  }
`
