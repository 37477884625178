import { useEffect, useRef, useState } from 'react'
import {
  DELETe_CONCEPT_TO_BILLING,
  GET_ALL_DOCUMENTS_TO_BILLING_CONCEPTS,
} from '../../graphql/Catalog/Billing/documenttobillingconcepts'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import ContentHeader from '../Layout/ContentHeader'
import Box from '../Global/Box'
import LayoutTable from '../Global/LayoutTable'
import { currencyFormatToAny } from '../Helpers/HelperCurrencyFormat'
import InformationBillingVerification from './Views/InformationBillingVerification'
import { RETRY_BILLING_DOCUMENT } from '../../graphql/Catalog/Billing/documenttobilling'
import ModalContent from '../Global/ModalContent'
import FormToCreateUpdateConceptBilling from './Views/Concepts/FormToCreateUpdateConceptBilling'

const DocumentToBillingNew = () => {
  const refModalConcepts = useRef(null)
  const { id: _id, show } = useParams()

  const [dataTable, setDataTable] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [conceptId, setConceptId] = useState(null)
  const [closeModal, setCloseModal] = useState(false)
  const [documentInvoice, setDocumentInvoice] = useState(false)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { data, loading, error, refetch } = useQuery(
    GET_ALL_DOCUMENTS_TO_BILLING_CONCEPTS,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
        idDocument: +_id,
      },
      fetchPolicy: 'no-cache',
    }
  )

  const [retryBilling] = useMutation(RETRY_BILLING_DOCUMENT)

  useEffect(() => {
    try {
      if (loading) return
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const concepts = data.getAllDocumentsToBillingConcepts

      let listTable = concepts.rows.map((item) => {
        return {
          id: item.id,
          sku: item.sku,
          descripción: item.description,
          cantidad: item.quantity,
          'Precio unitario': currencyFormatToAny({
            number: item.unit_price,
          }),
          subtotal: currencyFormatToAny({ number: item.subtotal }),
          impuestos: currencyFormatToAny({ number: item.tax }),
          'Tasa de impuesto':
            item.documentBillingConceptsToDocumentBillingTaxes.name,
          // 'Tasa de impuestos': item.tax_rate,
          total: currencyFormatToAny({ number: item.total }),
          'Código SAT': item.sat_code,
        }
      })

      setDataTable(listTable)
      setTotalCount(concepts.count)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [data, loading, error, refetch])

  const handleCreateConcept = () => {
    refModalConcepts.current.click()
  }

  const handleUpdateConcept = (id) => {
    setConceptId(id)
    refModalConcepts.current.click()
  }

  const handleCreateBillingOrRebilling = async (dataForm) => {
    try {
      // console.log(dataForm, 'data')

      const response = await toastSweetAlert({
        mode: 'modalConfirmCancel',
        message: '¿Está seguro de facturar este documento?',
      })

      if (!response.isConfirmed) return

      toastSweetAlert({
        mode: 'loading',
        message:
          +dataForm.status === 1
            ? 'Facturando documento, por favor espere...'
            : 'Reintentando facturar documento, por favor espere...',
      })
      const { data } = await retryBilling({
        variables: {
          documentId: dataForm.documentId ? +dataForm.documentId : +_id,
        },
        fetchPolicy: 'no-cache',
      })
      if (data.retryBillingDocument) {
        await refetch()
        return toastSweetAlert({
          mode: 'ok',
          message: 'El documento se ha facturado correctamente',
        })
      }
    } catch (error) {
      await refetch()
      return toastSweetAlert({
        mode: 'error',
        message: error.message ? error.message : 'Error al intentar facturar',
      })
    }
  }

  return (
    <>
      <ContentHeader title="" windowTitle="Facturación" />

      <Box
        btnRedTxt="Regresar"
        btnRedPath="/billing/documenttobilling"
        title=""
        btnSubmit={documentInvoice ? null : handleCreateBillingOrRebilling}
        btnSubmitText="Facturar"
        content={
          <>
            <div className="mb-4">
              <InformationBillingVerification
                documentId={_id}
                refetch={refetch}
                editDocument={true}
                handleCreateBillingOrRebilling={false}
                documentInvoice={documentInvoice}
                setDocumentInvoice={setDocumentInvoice}
                refModal={false}
              />
            </div>
            <LayoutTable
              withCard={false}
              hideId
              title="Conceptos de facturación"
              data={dataTable}
              totalCount={totalCount}
              pagePagination={pagePagination}
              setPagePagination={setPagePagination}
              extraHeaderButtonFunction={handleCreateConcept}
              extraHeaderButtonFunctionText="Agregar concepto"
              actionBtnEdit={handleUpdateConcept}
              hideActions={documentInvoice}
              headersButtons={!documentInvoice}
              hideAddNew
              hideDetails
              gql={DELETe_CONCEPT_TO_BILLING}
              refetchFunctionQueries={refetch}
              // PermissionsForPathNew={{
              //   path: `/documenttobilling/edit/${_id}`,
              //   create: false,
              //   edit: true,
              //   erase: true,
              // }}
              // hideActions
            />
          </>
        }
      />

      <ModalContent
        idModal="modalConcepts"
        title="Conceptos"
        size="extra"
        onClose={() => {
          setConceptId(null)
          setCloseModal(true)
        }}
        content={
          <>
            <FormToCreateUpdateConceptBilling
              conceptId={conceptId}
              documentId={_id}
              refModalConcepts={refModalConcepts}
              setConceptId={setConceptId}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
              refetchConcepts={refetch}
            />
          </>
        }
      />
      <span
        id="spanModalConcepts"
        ref={refModalConcepts}
        className="invisible"
        data-toggle="modal"
        data-target="#modalConcepts"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      ></span>
    </>
  )
}

export default DocumentToBillingNew
