export const CFDI_DATA = [
  { value: 'G01', label: 'Adquisición de mercancias', use: 'ambos' },
  {
    value: 'G02',
    label: 'Devoluciones, descuentos o bonificaciones',
    use: 'ambos',
  },
  { value: 'G03', label: 'Gastos en general', use: 'ambos' },
  { value: 'I01', label: 'Construcciones', use: 'ambos' },
  {
    value: 'I02',
    label: 'Mobilario y equipo de oficina por inversiones',
    use: 'ambos',
  },
  {
    value: 'I03',
    label: 'Equipo de transporte',
    use: 'ambos',
  },
  {
    value: 'I04',
    label: 'Equipo de computo y accesorios',
    use: 'ambos',
  },
  {
    value: 'I05',
    label: 'Dados, troqueles, moldes, matrices y herramental',
    use: 'ambos',
  },
  {
    value: 'I06',
    label: 'Comunicaciones telefónicas',
    use: 'ambos',
  },
  {
    value: 'I07',
    label: 'Comunicaciones satelitales',
    use: 'ambos',
  },
  {
    value: 'I08',
    label: 'Otra maquinaria y equipo',
    use: 'ambos',
  },
  {
    value: 'D01',
    label: 'Honorarios médicos, dentales y gastos hospitalarios',
    use: 'fisica',
  },
  {
    value: 'D02',
    label: 'Gastos médicos por incapacidad o discapacidad',
    use: 'fisica',
  },
  {
    value: 'D03',
    label: 'Gastos funerales',
    use: 'fisica',
  },
  {
    value: 'D04',
    label: 'Donativos',
    use: 'fisica',
  },
  {
    value: 'D05',
    label:
      'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',
    use: 'fisica',
  },
  {
    value: 'D06',
    label: 'Aportaciones voluntarias al SAR',
    use: 'fisica',
  },
  {
    value: 'D07',
    label: 'Primas por seguros de gastos médicos',
    use: 'fisica',
  },
  {
    value: 'D08',
    label: 'Gastos de transportación escolar obligatoria',
    use: 'fisica',
  },
  {
    value: 'D09',
    label:
      'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',
    use: 'fisica',
  },
  {
    value: 'D10',
    label: 'Pagos por servicios educativos (colegiaturas)',
    use: 'fisica',
  },
  {
    value: 'P01',
    label: 'Por definir',
    use: 'ambos',
  },
  {
    value: 'S01',
    label: 'Sin efectos fiscales',
    use: 'ambos',
  },
]

export const PAYMENT_METHODS = [
  { value: 'PUE', label: 'Pago en una sola exhibición' },
  { value: 'PPD', label: 'Pago en parcialidades o diferido' },
]

export const PAYMENT_FORMS = [
  { value: '01', label: 'Efectivo' },
  { value: '02', label: 'Cheque nominativo' },
  { value: '03', label: 'Transferencia electrónica de fondos' },
  { value: '04', label: 'Tarjeta de crédito' },
  { value: '05', label: 'Monedero electrónico' },
  { value: '06', label: 'Dinero electrónico' },
  { value: '08', label: 'Vales de despensa' },
  { value: '12', label: 'Dación en pago' },
  { value: '13', label: 'Pago por subrogación' },
  { value: '14', label: 'Pago por consignación' },
  { value: '15', label: 'Condonación' },
  { value: '17', label: 'Compensación' },
  { value: '23', label: 'Novación' },
  { value: '24', label: 'Confusión' },
  { value: '25', label: 'Remisión de deuda' },
  { value: '26', label: 'Prescripción o caducidad' },
  { value: '27', label: 'A satisfacción del acreedor' },
  { value: '28', label: 'Tarjeta de débito' },
  { value: '29', label: 'Tarjeta de servicios' },
  { value: '30', label: 'Aplicación de anticipos' },
  { value: '31', label: 'Intermediario pagos' },
  { value: '99', label: 'Por definir' },
]

export const OPTIONS_MONTH = [
  { value: '01', label: 'Enero' },
  { value: '02', label: 'Febrero' },
  { value: '03', label: 'Marzo' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Mayo' },
  { value: '06', label: 'Junio' },
  { value: '07', label: 'Julio' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Septiembre' },
  { value: '10', label: 'Octubre' },
  { value: '11', label: 'Noviembre' },
  { value: '12', label: 'Diciembre' },
]

export const STATUS_OPTIONS_DOCUMENT = [
  {
    value: 1,
    label: 'Pendiente',
  },
  {
    value: 2,
    label: 'Facturado',
  },
  {
    value: 99,
    label: 'Reintentar',
  },
]

export const REGIMEN_FISCAL_OPTIONS = [
  { key: '601', name: 'General de Ley Personas Morales' },
  { key: '603', name: 'Personas Morales con Fines no Lucrativos' },
  {
    key: '605',
    name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
  },
  { key: '606', name: 'Arrendamiento' },
  {
    key: '607',
    name: 'Régimen de Enajenación o Adquisición de Bienes',
  },
  { key: '608', name: 'Demás ingresos' },
  { key: '609', name: 'Consolidación' },
  {
    key: '610',
    name: 'Residentes en el Extranjero sin Establecimiento Permanente en México',
  },
  {
    key: '611',
    name: 'Ingresos por Dividendos (socios y accionistas)',
  },
  {
    key: '612',
    name: 'Personas Físicas con Actividades Empresariales y Profesionales',
  },
  { key: '614', name: 'Ingresos por intereses' },
  {
    key: '615',
    name: 'Régimen de los ingresos por obtención de premios',
  },
  { key: '616', name: 'Sin obligaciones fiscales' },
  {
    key: '620',
    name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
  },
  { key: '621', name: 'Incorporación Fiscal' },
  {
    key: '622',
    name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
  },
  { key: '623', name: 'Opcional para Grupos de Sociedades' },
  { key: '624', name: 'Coordinados' },
  {
    key: '625',
    name: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
  },
  { key: '626', name: 'Régimen Simplificado de Confianza' },
  { key: '628', name: 'Hidrocarburos' },
  {
    key: '629',
    name: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
  },
  { key: '630', name: 'Enajenación de acciones en bolsa de valores' },
]
