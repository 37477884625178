import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  CREATE_CLIENT_BILLING,
  GET_ONE_CLIENT_TO_BILLING_QUERY,
  UPDATE_CLIENT_BILLING,
} from '../../../graphql/Catalog/Billing/documenttobillingclients'
import Box from '../../Global/Box'
import InputController from '../../Global/InputController'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { REGIMEN_FISCAL_OPTIONS } from '../DataBilling/DataBilling'
const DocumentToBillingClientsNew = ({
  refModal,
  idClient,
  setIdClient,
  closeModal,
  setCloseModal,
  refetch,
  show,
  setShow,
}) => {
  const [optionsFiscalRegime, setOptionsFiscalRegime] = useState([])
  const [selectedFiscalRegime, setSelectedFiscalRegime] = useState(null)
  const validationSchema = yup.object().shape({
    name: yup.string(),
    rfc: yup.string(),
    tax_system: yup.string(),
    zip_code: yup.string(),
    email: yup.string(),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { loading, error, data } = useQuery(GET_ONE_CLIENT_TO_BILLING_QUERY, {
    variables: {
      getDocumentToBillingClientByIdId: idClient,
    },
    fetchPolicy: 'no-cache',
  })

  const [createClient] = useMutation(CREATE_CLIENT_BILLING)
  const [updateClient] = useMutation(UPDATE_CLIENT_BILLING)
  useEffect(() => {
    try {
      if (!idClient) return
      if (loading) return
      if (error)
        return toastSweetAlert(
          {
            mode: 'error',
            message: error.message,
          },
          handleCloseModal()
        )

      const dataClient = data.getDocumentToBillingClientById

      const dataForm = {
        name: dataClient.name,
        rfc: dataClient.rfc,
        tax_system: dataClient.tax_system,
        zip_code: dataClient.zip_code,
        email: dataClient.email,
      }

      setSelectedFiscalRegime(dataClient.tax_system)
      reset(dataForm)
    } catch (error) {
      handleCloseModal()
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [loading, error, data])

  useEffect(() => {
    try {
      const options = REGIMEN_FISCAL_OPTIONS.map((item) => {
        return {
          value: item.key,
          label: `${item.key} - ${item.name}`,
        }
      })

      setOptionsFiscalRegime(options)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [REGIMEN_FISCAL_OPTIONS])

  useEffect(() => {
    if (closeModal) {
      reset({
        name: '',
        rfc: '',
        tax_system: '',
        zip_code: '',
        email: '',
      })
      setSelectedFiscalRegime(null)
      setCloseModal(false)
    }
  }, [closeModal])

  const handleCloseModal = () => {
    reset({
      name: '',
      rfc: '',
      tax_system: '',
      zip_code: '',
      email: '',
    })
    // setCloseModal(false)
    setSelectedFiscalRegime(null)
    setIdClient(null)
    setShow(false)
    refModal.current.click()
  }

  const onSubmit = async (data) => {
    try {
      toastSweetAlert({
        mode: 'loading',
        message: 'Guardando cliente...',
      })

      if (idClient) {
        const { data: dataUpdateClient } = await updateClient({
          variables: {
            updateDocumentToBillingClientId: +idClient,
            documentToBillingClientsInput: {
              name: data.name,
              rfc: data.rfc,
              tax_system: data.tax_system,
              zip_code: data.zip_code,
              email: data.email,
            },
          },
        })

        if (dataUpdateClient.updateDocumentToBillingClient) {
          await refetch()
          handleCloseModal()
          return toastSweetAlert(
            {
              mode: 'ok',
              message: 'Cliente actualizado correctamente',
            },
            handleCloseModal()
          )
        }
      }

      const { data: dataClient } = await createClient({
        variables: {
          documentToBillingClientsInput: {
            name: data.name,
            rfc: data.rfc,
            tax_system: data.tax_system,
            zip_code: data.zip_code,
            email: data.email,
          },
        },
      })

      if (dataClient.createDocumentToBillingClient) {
        await refetch()
        handleCloseModal()
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Cliente creado correctamente',
          },
          handleCloseModal()
        )
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            btnSubmit
            btnSubmitText={idClient ? 'Actualizar' : 'Crear'}
            withCard={false}
            btnSecondFunction={handleCloseModal}
            btnSecondFunctionTitle="Cerrar"
            btnSecondFunctionDismissModal
            content={
              <>
                <div className="row p-2">
                  <div className="col-lg-12">
                    <InputController
                      control={control}
                      name="name"
                      label="Nombre"
                      disabled={show}
                    />
                  </div>
                  <div className="col-lg-12">
                    <InputController
                      control={control}
                      name="rfc"
                      label="RFC"
                      disabled={show}
                    />
                  </div>
                  <div className="col-lg-12">
                    <InputController
                      control={control}
                      name="tax_system"
                      label="Régimen Fiscal"
                      options={optionsFiscalRegime}
                      changeAction={(e) =>
                        setSelectedFiscalRegime(e ? e.value : null)
                      }
                      valueManual={
                        selectedFiscalRegime ? selectedFiscalRegime : ''
                      }
                      inputType="choosen"
                      disabled={show}
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="col-lg-12">
                    <InputController
                      control={control}
                      name="zip_code"
                      label="Código Postal"
                      disabled={show}
                    />
                  </div>
                  <div className="col-lg-12">
                    <InputController
                      control={control}
                      name="email"
                      label="Correo Electrónico"
                      disabled={show}
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default DocumentToBillingClientsNew
