import { useState } from 'react'

const Tabs = ({ tabsContent = null }) => {
  const [activeTab, setActiveTab] = useState(1)

  // tabs = [
  //   { id: 1, name: 'Tab 1', content: 'Contenido de la pestaña 1' },
  //   {
  //     id: 2,
  //     name: 'Tab 2',
  //     content: (
  //       <>
  //         <h1>Contenido</h1>
  //         <p>Contenido de la pestaña 2</p>
  //         <div className="row">
  //           <div className="col-6">
  //             <input
  //               type="text"
  //               className="form-control"
  //               placeholder="Nombre"
  //             />
  //           </div>
  //         </div>
  //       </>
  //     ),
  //   },
  //   {
  //     id: 3,
  //     name: 'Tab 3',
  //     content: 'Contenido de la pestaña 3',
  //     // disabled: true,
  //   },
  // ]

  // console.log('tabsContent', tabsContent)

  return (
    <div>
      {/* Navegación de tabs */}
      <ul className="nav nav-tabs">
        {tabsContent &&
          tabsContent.length > 0 &&
          tabsContent.map((tab) => (
            <li className="nav-item" key={tab.id}>
              <a
                style={{
                  cursor: tab.disabled ? 'not-allowed' : 'pointer',
                  color: tab.disabled ? 'gray' : 'black',
                }}
                className={`nav-link ${tab.id === activeTab ? 'active' : ''} ${
                  tab.disabled ? 'disabled' : ''
                }`}
                href={tab.href}
                onClick={() => !tab.disabled && setActiveTab(tab.id)}
                aria-current={tab.id === activeTab ? 'page' : undefined}
                aria-disabled={tab.disabled ? 'true' : undefined}
              >
                {tab.name}
              </a>
            </li>
          ))}
      </ul>

      {/* Contenido dinámico basado en la pestaña activa */}
      <div className="tab-content">
        {tabsContent &&
          tabsContent.length > 0 &&
          tabsContent.find((tab) => tab.id === activeTab).content}
      </div>
    </div>
  )
}

export default Tabs
