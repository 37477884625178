import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import {
  DELETE_CLIENT_TO_BILLING_MUTATION,
  GET_ALL_CLIENTS_TO_BILLING_QUERY,
  SYNC_CLIENT_TO_BILLING_MUTATION,
} from '../../../graphql/Catalog/Billing/documenttobillingclients'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import LayoutTable from '../../Global/LayoutTable'
import ModalContent from '../../Global/ModalContent'
import DocumentToBillingClientsNew from './DocumentToBillingClientsNew'

const DocumentToBillingClients = () => {
  const refModal = useRef(null)

  const [idClient, setIdClient] = useState(null)
  const [closeModal, setCloseModal] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [show, setShow] = useState(false)

  const [dataTable, setDataTable] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_CLIENTS_TO_BILLING_QUERY,
    {
      variables: {
        searchQuery: pagePagination.searchQuery,
        limit: pagePagination.limit,
        offset: pagePagination.offset,
      },
      fetchPolicy: 'no-cache',
    }
  )

  const [syncClients] = useMutation(SYNC_CLIENT_TO_BILLING_MUTATION)
  const [deleteClient] = useMutation(DELETE_CLIENT_TO_BILLING_MUTATION)
  useEffect(() => {
    try {
      if (loading) return
      if (error)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      const rows = data.getAllDocumentsToBillingClients.rows.map((row) => {
        return {
          id: row.id,
          'id Factura': row.invoice_uid,
          nombre: row.name,
          rfc: row.rfc,
        }
      })

      setDataTable(rows)
      setTotalCount(data.getAllDocumentsToBillingClients.count)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }, [loading, error, data])

  const handleEditClient = (id) => {
    setIdClient(id)
    // setShow(true)
    refModal.current.click()
  }

  const handleCreateClient = () => {
    // setIdClient(null)
    // setShow(true)
    refModal.current.click()
  }

  const handleDeleteClient = async (id) => {
    try {
      const response = await toastSweetAlert({
        icon: 'warning',
        title: 'Eliminar Cliente',
        mode: 'modalConfirmCancel',
        message: '¿Desea eliminar al cliente solo en suite?',
        confirmButtonText: 'Solo en Suite',
        denyButtonText: 'En Suite y Factura',
      })

      if (response.isConfirmed) {
        const { data } = await deleteClient(
          {
            variables: {
              id: id,
              deleteExternal: false,
            },
          },
          toastSweetAlert({
            mode: 'loading',
            message: 'Eliminando cliente en suite...',
          })
        )

        if (data.deleteDocumentToBillingClient) {
          await refetch()
          return toastSweetAlert({
            mode: 'ok',
            message: 'Cliente eliminado correctamente solo en suite',
          })
        }
      }

      if (response.isDenied) {
        const { data } = await deleteClient(
          {
            variables: {
              id: id,
              deleteExternal: true,
            },
          },
          toastSweetAlert({
            mode: 'loading',
            message: 'Eliminando cliente en suite y factura.com...',
          })
        )

        if (data.deleteDocumentToBillingClient) {
          await refetch()
          return toastSweetAlert({
            mode: 'ok',
            message: 'Cliente eliminado correctamente en suite y factura',
          })
        }
      }
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleSyncClients = async () => {
    try {
      setDisableButton(true)
      const { data } = await syncClients()

      if (data.syncDocumentToBillingClients) {
        await refetch()
        setDisableButton(false)
        return toastSweetAlert({
          mode: 'ok',
          message: 'Clientes sincronizados correctamente',
        })
      }
    } catch (error) {
      setDisableButton(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <ContentHeader title="" windowTitle="Clientes Facturación" />

      <LayoutTable
        hideId
        data={dataTable}
        totalCount={totalCount}
        loadingData={loading}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        gql={DELETE_CLIENT_TO_BILLING_MUTATION}
        actionBtnErase={handleDeleteClient}
        refetchFunctionQueries={refetch}
        actionBtnEdit={handleEditClient}
        extraHeaderButtonFunction={handleCreateClient}
        extraHeaderButtonFunctionText="Agregar cliente"
        headerButtonsNoPermissions={
          <>
            <button
              className="btn btn-sm btn-accept"
              type="button"
              onClick={() => handleSyncClients()}
              disabled={disableButton}
            >
              Sincronizar Clientes
            </button>
          </>
        }
      />

      <ModalContent
        idModal="modalClient"
        ref={refModal}
        title="Cliente"
        size="large"
        onClose={() => {
          setCloseModal(true)
          setIdClient(null)
          setShow(false)
        }}
        content={
          <DocumentToBillingClientsNew
            refModal={refModal}
            closeModal={closeModal}
            setCloseModal={setCloseModal}
            idClient={idClient}
            setIdClient={setIdClient}
            show={show}
            setShow={setShow}
            refetch={refetch}
          />
        }
      />

      <span
        id="spanModalClient"
        ref={refModal}
        className="invisible"
        data-toggle="modal"
        data-target="#modalClient"
        data-backdrop="static"
        data-keyboard="false"
        data-dismiss="modal"
      ></span>
    </>
  )
}

export default DocumentToBillingClients
